<template>
  <div class="wengao container">
    <Tips />
    <Article :article="article" :audioSrc="audioSrc" />
  </div>
</template>

<script>
  import Article from "@/components/article";
  import { getPreviewDate } from "@/utils/api";
  import { wxShare } from "@/utils/wxShare";
  import Tips from "@/components/tips";
  export default {
    name: "previewyinpin",
    components: {
      Article,
      Tips,
    },
    data() {
      return {
        canShow: false,
        id: "",
        path: "kczDetail",
        adver: {
          title: "",
          src: "",
        },
        adver2: {
          title: "",
          src: "",
        },
        article: {
          title: "",
          source: "",
          time: "",
          readNum: "",
          content: "",
        },
        tuijianList: [],
        tujianTitle: "推荐阅读",
        commentList: [],
        audioSrc: "",
        share_config: {},
      };
    },
    methods: {
      async getPreviewDate(id) {
        const res = await getPreviewDate(id);
        const data = res.data.data;
        this.article.title = data.title;
        this.article.source = data.source_from;
        this.article.time = data.published_at;
        this.article.readNum = data.views;
        this.article.content = data.content;
        this.audioSrc = data.materials.audio.src;
        this.share_config = data.share_config;
        this.canShow = true;
        document.title = "预览-" + data.title;
        //wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
        wxShare("快艺学APP", "https://sharekcz.cztv.tv/cbs.png", "https://sharekcz.cztv.tv/index", "常州，教我如何不想她");
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getPreviewDate(this.id);
    },
  };
</script>

<style lang="scss">
  .audio-wrapper {
    background-color: #fff;
    margin: 0.4rem auto;
    width: 9.2rem;
    border: 1px solid #ccc;
    border-radius: 0.16rem;
    height: 1rem;
    display: flex;
    align-items: center;
  }
  .audio-left {
    text-align: center;
    width: 18%;
  }
  .audio-left img {
    width: 0.72rem;
    height: 0.72rem;
    position: relative;
    display: initial; /* 解除与app的样式冲突 */
    cursor: pointer;
  }
  .audio-right {
    margin-right: 2%;
    width: 80%;
  }
  .progress-bar-bg {
    background-color: #d9d9d9;
    position: relative;
    height: 0.1rem;
    cursor: pointer;
    border-radius: 0.1rem;
  }

  .progress-bar {
    background-color: #f04040;
    width: 0;
    height: 0.1rem;
    border-radius: 0.1rem;
  }

  .progress-bar-bg span {
    content: " ";
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #404040;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.1rem;
    margin-left: -0.1rem;
    cursor: pointer;
  }

  .audio-time {
    overflow: hidden;
    margin-top: 0.1rem;
  }

  .audio-length-total {
    float: right;
    font-size: 12px;
    color: #494949;
  }

  .audio-length-current {
    float: left;
    font-size: 12px;
    color: #494949;
  }
</style>
